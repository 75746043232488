import { useTranslations } from "next-intl";

import Dialog from "~/components/common/dialog";
import Icon from "~/components/common/icon";

import styles from "./social-dialog.module.scss";

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  name: string;
  type: string;
  externalPostId: string;
  postLink: string;
};

export default function SocialDialog({ isOpen = true, onClose, name, type, externalPostId, postLink }: Props) {
  const t = useTranslations();
  return (
    <Dialog
      isOpen={isOpen}
      title={
        <>
          {type === "tiktok" ? <Icon name="tiktok" /> : type === "instagram" ? <Icon name="instagram" /> : null}
          <span>{name}</span>
        </>
      }
      onClose={onClose}
      customStyles={{ wrapper: styles.wrapperDialog, panel: styles.panelDialog }}
    >
      <div>
        {type === "tiktok" ? (
          <iframe src={`https://www.tiktok.com/embed/v2/${externalPostId}`} className={styles.IFrame} allowFullScreen />
        ) : type === "instagram" ? (
          <iframe src={`${postLink}embed/captioned`} className={styles.IFrame} allowFullScreen />
        ) : null}
      </div>
    </Dialog>
  );
}
